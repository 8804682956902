import '../styles/homeandlifepage.css';

import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

import FaqSection from '../components/faq-row';
import Layout from '../components/layout';
import Seo from '../components/seo';
import auto_insurance from '../images/Auto_Insurance_(Icon).svg';

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = new Set();
  data.forEach( element => {
    uniqueSectionArray.add( element.node.data_category );
  } );
  return [...uniqueSectionArray];
};

let getformattedData2 = ( data, sectionsArray ) => {
  let output = {};
  let section;
  let column;

  sectionsArray.forEach( sectionValue => {
    output[sectionValue] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionValue && item.node );
    for ( let col = 1; col < 5; col++ ) {
      output[sectionValue]['column_' + col] = {};
      column = section.filter( ( item ) => item.node.column == col && item.node );
      let len = column.length;
      for ( let j = 0; j < len; j++ ) {
        output[sectionValue]['column_' + col][column[j].node.style_category] = column[j].node;
      }

      if ( Object.keys( output[sectionValue]['column_' + col] ).length === 0 ) {
        delete output[sectionValue]['column_' + col];
      }
    }
  } );

  return output;
};

function AutoInsurance() {

  const data = useStaticQuery(
    graphql`
    query {
      allStrapiPageData(filter: {page_name: {eq: "life_insurance_landing_page"}}) {
        edges {
          node {
            data_category
            column
            content
            style_category
            attribute_1_value
            attribute_2_value
            attribute_1_key
            attribute_2_key
          }
        }
      }
    }
    `
  );

  const [isLifeInsurance, changeCarrier] = useState( true );
  const [formattedData, setFormattedData] = useState();
  const [faqArray, setFaq] = useState( [] );


  useEffect( () => {

    let pageData = data.allStrapiPageData.edges;
    let uniqueSectionData = getUniqueSectionData( pageData );
    const formattedData = getformattedData2( pageData, uniqueSectionData );
    setFormattedData( formattedData );

  }, [data] );

  useEffect( () => {
    const faqArray = [
      {
        question: 'Does comprehensive coverage cover everything?',
        answer: 'Comprehensive coverage is for when your car is damaged by certain reasons other than a car accident - like when you hit a deer or hail pummels your car parked in the driveway. Comprehensive coverage will also reimburse you the market value of your car if it&#39;s stolen.'
      },
      {
        question: 'Will my auto insurance cover the costs of damage caused by a friend who borrowed my car?',
        answer: 'If the car is registered in your name, you are responsible for damages caused by it, even when your car is borrowed by a friend with. The only way that your friend&#39;s insurance company will pay is if the damages exceed your limits. If your friend was injured and has personal injury protection (PIP) on their personal auto policy, they would use their own policy to cover those costs.'
      },
      {
        question: 'Do men pay more for auto insurance than women?',
        answer: 'Eariler, men used to be considered high-risk drivers compared to women. This is not necessarily consistent across all age groups. In a few states, insurance providers are not allowed to use gender as a pricing factor.'
      },
      {
        question: 'What is the difference between comprehansive, liability, and collision?',
        answer: '-Comprehensive coverage is for when your vehicle is damaged by something other than a collision like a falling tree, flood, hitting a deer, and the likes. Your auto insurance would reimburse your repair or replacement costs. Comprehensive coverage also reimburses you if your car is stolen. In all cases, you&#39;d be required to pay a deductible, which is your share of those costs. - Liability Insurance covers the damage caused to other vehicles in an accident, other property, and medical care if you&#39;re at fault. It does not cover damage to your own car or your own medical bills.- Collision is an optional coverage on your auto insurance policy. In case you&#39;re responsible for an accident involving another vehicle, where your vehicle is damaged, this coverage would reimburse you for repair or replacement costs for your own vehicle.'
      },
      {
        question: 'How is auto insurance priced?',
        answer: 'Auto insurance pricing is determined by various factors like your age, driving history, credit score, where you live, the kind of car you drive, how often you drive your car, marital status, and your insurance history among a few others.'
      }
    ];
    setFaq( faqArray );
  }, [isLifeInsurance] );

  return(
    <Layout header={'menu'}>
      <Seo
        canonicalUrl="https://www.getmybubble.com/auto-insurance/"
        schema={[
          JSON.stringify(   {
            '@context': 'http://schema.org',
            '@type': 'Product',
            'name': 'Auto Insurance',
            'aggregateRating': { '@type': 'AggregateRating', 'ratingValue': '4.4 ', 'reviewCount': '6' } } ),
          JSON.stringify(  {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'url': 'https://www.getmybubble.com/',
            'logo': 'https://www.getmybubble.com/static/buble-077b4498b854b2301e9cbf5a55b69b3d.svg'
          } )]}/>

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="lifehomebg container-fluid d-flex justify-content-center" style={{ marginTop: '65px' }}>

          <div className="Menu-first-sec">

            <h1 className="heading">
              <img src={auto_insurance}
                alt="Auto Insurance"/>
                  Auto Insurance
            </h1>

            <p>
              Auto insurance is a financial contract between your insurance provider and you to protect you and your vehicle from physical damage or bodily injury caused by motor accidents on road
            </p>

            <div className="flexRow btns">
              <Link to="/" className="orangeBtn">Get a quote now</Link>

              <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                <button type="button" className="explore"> Explore </button>   </a>
            </div>
          </div>

          <div className="autobox">
            <img src="https://cdn.getmybubble.com/images/corporate-sites/auto-insurance.svg" alt="Buy Bubble Auto Insurance"/>
          </div>
        </div>

        <div className={'containerCustomStyles whatIsLife home'} id="start-exploring" >
          <div className="infoBox flexRow justify-content-around">
            <div className="flexColumn imgPaddingTop">
              <img src="https://devuiassetscdn.getmybubble.io/images/group-138.png"
                alt="man-with-paper-plane" />

              <h2 className="section-title">
                What is Auto Insurance?
              </h2>
            </div>

            <div className="container">
              <div className="vertically-center-para row">
                <div className="col-md-12 columna1-text">
                  Auto insurance protects you and your vehicle in case of an accident, if your car is vandalized, stolen, or any damage is caused by natural disasters. Besides getting coverage for physical damage to the car and bodily injury of the driver and/or passengers, you can get additional coverage for damage to another vehicle, towing, and getting a rental car due to an accident.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="insuranceCovers flexRow justify-content-center">
          <div className="flexColumn containerCustomStyles">
            <h2 className="section-title">
              What does Auto Insurance cover?
            </h2>

            <div className="flexRow coverInfo">
              <div className="coverCard">
                <div className="cardTitle">
                  Bodily injury
                </div>

                <div className="cardText">
                  If you are in an accident and there is any injury caused to the driver or/and passengers of the other car, auto insurance pays for the medicals bills.
                </div>
              </div>

              <div className="coverCard">
                <div className="cardTitle">
                  Physical damage and collision
                </div>

                <div className="cardText">
                  In case of an accident, auto insurance pays for the physical damage caused to the other car or when your car is in a collision with a tree.
                </div>
              </div>

              <div className="coverCard">
                <div className="cardTitle">
                  Comprehensive
                </div>

                <div className="cardText">
                  If your car is hit by another object or is damaged by fire or flooding, auto insurance pays for the mentioned damage.
                </div>
              </div>

              <div className="coverCard">
                <div className="cardTitle">
                  Other factors
                </div>

                <div className="cardText">
                  Auto insurance can also pay for a rental car in case your car is too damaged, if you are in an accident with an uninsured motorist, if your car needs to be towed, or if you need an ambulance.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="insurancePolicies flexRow justify-content-center d-none">
          <div className="containerCustomStyles flexColumn">
            <h3 className="section-title">
              Our Policies
            </h3>

            <div className="flexRow">
              <div className="policyCard flexColumn  ">
                <div className="lightBlueBg">
                  <img src="https://devuiassetscdn.getmybubble.io/images/stillwater-logo.png" alt="Logo" />

                  <div className="flexRow justify-content-between">
                    <div className="title">Primary</div>

                    <div className="flexRow align-items-center">
                      <div className="grade">A+</div>

                      <div className="gradeText">  A.M Best </div>
                    </div>
                  </div>
                </div>

                <div className="flexColumn  policyInfo ">
                  <div className="policyDescription">Coverage designed to protect&nbsp;your home and belongings from any surprises.</div>

                  <div className="flexColumn">
                    <div className="flexRow justify-content-between mt16px">
                      <div className="flexColumn">
                        <div className="label">Deductible</div>

                        <div className="policyAmount">$2,000</div>
                      </div>

                      <div className="flexColumn">
                        <div className="label">Liability Coverage</div>

                        <div className="policyAmount">$500,000</div>
                      </div>
                    </div>

                    <div className="flexRow justify-content-between mt16px">
                      <div className="flexColumn">
                        <div className="label">Personal Property</div>

                        <div className="policyAmount">$30,000</div>
                      </div>

                      <div className="flexColumn">
                        <div className="label">Replacement Cost</div>

                        <div className="policyAmount">$589,093</div>
                      </div>
                    </div>
                  </div>

                  <div className="flexRow justify-content-center"><a title="Stillwater" href="/stillwater"><button className="orangeBtn">Learn More</button></a></div>
                </div>
              </div>

              <div className="policyCard flexColumn  ">
                <div className="lightBlueBg">
                  <img src="https://devuiassetscdn.getmybubble.io/images/stillwater-logo.png" alt="Logo" />

                  <div className="flexRow justify-content-between">
                    <div className="title">Primary</div>

                    <div className="flexRow align-items-center">
                      <div className="grade">A+</div>

                      <div className="gradeText">  A.M Best </div>
                    </div>
                  </div>
                </div>

                <div className="flexColumn  policyInfo ">
                  <div className="policyDescription">Coverage designed to protect&nbsp;your home and belongings from any surprises.</div>

                  <div className="flexColumn">
                    <div className="flexRow justify-content-between mt16px">
                      <div className="flexColumn">
                        <div className="label">Deductible</div>

                        <div className="policyAmount">$2,000</div>
                      </div>

                      <div className="flexColumn">
                        <div className="label">Liability Coverage</div>

                        <div className="policyAmount">$500,000</div>
                      </div>
                    </div>

                    <div className="flexRow justify-content-between mt16px">
                      <div className="flexColumn">
                        <div className="label">Personal Property</div>

                        <div className="policyAmount">$30,000</div>
                      </div>

                      <div className="flexColumn">
                        <div className="label">Replacement Cost</div>

                        <div className="policyAmount">$589,093</div>
                      </div>
                    </div>
                  </div>

                  <div className="flexRow justify-content-center"><a title="Stillwater" href="/stillwater"><button className="orangeBtn">Learn More</button></a></div>
                </div>
              </div>

              <div className="policyCard flexColumn  ">
                <div className="lightBlueBg">
                  <img src="https://devuiassetscdn.getmybubble.io/images/stillwater-logo.png" alt="Logo" />

                  <div className="flexRow justify-content-between">
                    <div className="title">Primary</div>

                    <div className="flexRow align-items-center">
                      <div className="grade">A+</div>

                      <div className="gradeText">  A.M Best </div>
                    </div>
                  </div>
                </div>

                <div className="flexColumn  policyInfo ">
                  <div className="policyDescription">Coverage designed to protect&nbsp;your home and belongings from any surprises.</div>

                  <div className="flexColumn">
                    <div className="flexRow justify-content-between mt16px">
                      <div className="flexColumn">
                        <div className="label">Deductible</div>

                        <div className="policyAmount">$2,000</div>
                      </div>

                      <div className="flexColumn">
                        <div className="label">Liability Coverage</div>

                        <div className="policyAmount">$500,000</div>
                      </div>
                    </div>

                    <div className="flexRow justify-content-between mt16px">
                      <div className="flexColumn">
                        <div className="label">Personal Property</div>

                        <div className="policyAmount">$30,000</div>
                      </div>

                      <div className="flexColumn">
                        <div className="label">Replacement Cost</div>

                        <div className="policyAmount">$589,093</div>
                      </div>
                    </div>
                  </div>

                  <div className="flexRow justify-content-center"><a title="Stillwater" href="/stillwater"><button className="orangeBtn">Learn More</button></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container ourPartners flexRow ">
        <div className="containerCustomStyles flexColumn">
          <h3 className="section-title text-center">
            Our Partner
          </h3>

          <div className="flexRow justify-content-center">
            <div className="partner flexColumn">
              <div className="flexRow">
                <div className="imgBox">
                  <img src="https://devuiassetscdn.getmybubble.io/images/stillwater-logo.png" alt="Stillwater" className="m-0" />
                </div>

                <div className="partnerName">Stillwater</div>
              </div>

              <p className="partnerDesc">Stillwater was founded in 2000 as the Fidelity National Property and Casualty Insurance Group, a branch of FNF, the country's largest provider of commercial and residential title and mortgage services.</p>

              <div className="flexRow align-items-center mb11">
                <div className="grade">AA-</div>

                <div className="gradeText"> S&amp;P Global </div>
              </div>

              <div className="flexRow align-items-center">
                <div className="grade">A+</div>

                <div className="gradeText"> A.M Best </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="plansForYou">
        <div className="orangeBackGround">
          <div>
                            Find out which plan is right for you
          </div>

          <div className="customizedPlan">
                            Policies are customized according to your needs.
                            We have simplified the process to help you find the right policy that fits your budget and loved ones.
          </div>

          <Link to="/" type="button" className="btnWhite">
                            Get your quote now
          </Link>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row info-para">
          <div className="col-lg-2"></div>

          <div className="col-lg-8 d-md-12">
            <FaqSection  faqs={faqArray}/>
          </div>
        </div>
      </div>


    </Layout>
  );
}

export default AutoInsurance;
